import jobRep from './JobsRep'
import Job from './Job'
import jobsListSettings from './JobsListSettings'
import { fetchConsultants, fetchJobTitlesEn, fetchTalents } from './AssignmentFetch'
import { defer } from 'react-router-dom'

export async function JobsListLoader () {
  const jobs = fetchDataForAssignments(jobsListSettings.activeOnly)
  const jobTitlesEn = fetchJobTitlesEn()
  return defer({ jobTitlesEn, jobs })
}

export async function JobsListAction () { return 'return' }

const fetchJobs = async (consultants, talents, activeOnly = true) => {
  // jobs
  const performanceCounter = performance.now()
  const jobObjects = await jobRep.getAllJobs()
  console.log('Load jobs: ', performance.now() - performanceCounter)
  if (!jobObjects) return []
  for (const document of jobObjects) {
    if (document.jobComment.length > 0) {
      document.jobComment = document.jobComment[0].comment
    } else {
      document.jobComment = null
    }
  }
  // create Job objects
  if (activeOnly) return jobObjects.filter(row => row.contractStatus !== false).map(row => new Job(row, talents, consultants))
  else return jobObjects.map(row => new Job(row, talents, consultants))
}

export const fetchDataForAssignments = async (activeOnly) => {
  const performanceCounter = performance.now()
  const [consultants, talents] = await Promise.all([fetchConsultants(), fetchTalents()])
  console.log('Load requirements: ', performance.now() - performanceCounter)
  const jobs = await fetchJobs(consultants, talents, activeOnly)
  return jobs // Returns only the needed data
}
