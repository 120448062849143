import { OAuthProvider } from '@firebase/auth'

/**
 * Microsoft Azure Active Directory federated OAuth 2 authentication provider.
 */
export default class AzureAdAuthProvider extends OAuthProvider {
  /**
     * Create provider
     * @param {string} tenant Tenant GUID
     */
  constructor (tenant) {
    super('microsoft.com')
    this.setCustomParameters({ tenant })
  }
}
