/** Exportable global variable for assignments page */
class JobsListSettings {
  /**
   * Construct JobsListSettings
   * @param {boolean} activeOnly Whether assignments list shows only active assignments
   */
  constructor () {
    this.activeOnly = true
  }

  /**
   * Sets whether assignments list shows only active assignments
   * @param {bool} bool Boolean to set
   */
  setActiveOnly (bool) {
    this.activeOnly = Boolean(bool)
  }
}

const jobsListSettings = new JobsListSettings()
export default jobsListSettings
