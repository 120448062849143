import { lazy } from 'react'
import LazyLoadUtils from './services/LazyloadUtils'
import ProtectedRouteLoader from './login/ProtectedRoute.loader'
import { JobAddViewLoader } from './jobs/JobAddView.loader'
import TalentDialogConsultantLoader from './talents/TalentDialogConsultant.loader'
import { JobsListLoader, JobsListAction } from './jobs/JobsList.loader'
import JobsSidebarLoader from './layout/jobs/JobsSidebar.loader'
import JobsDataGridLoader from './layout/jobs/JobsDataGrid.loader'
import { TalentsListScreenLoader } from './talents/TalentsListScreen.loader'
import { WorkShiftsAction, WorkShiftsLoader } from './views/shiftList/WorkShifts.loader'
import { UpcomingShiftsAction } from './views/shiftList/UpcomingShifts.loader'
import { OpenShiftLoader } from './views/data/OpenShifts.loader'
import LoginHelpDialog from './components/LoginHelpDialog'
const AuthRestoreScreen = lazy(() => import('./views/backup/AuthRestoreScreen'))
const AdminLoginScreen = lazy(() => import('./login/AdminLoginScreen'))
const ClaimsScreen = lazy(() => import('./user/ClaimsScreen'))
const ErrorPageData = lazy(() => import('./layout/ErrorPageData'))
const LoginContent = lazy(() => import('./login/LoginContent'))
const PresentationInfoPage = lazy(() => import('./components/PresentationInfoPage'))
const PublicAssignmentForm = lazy(() => import('./components/PublicAssignmentForm'))
const Root = lazy(() => import('./layout/Root'))
const SickLeaveForm = lazy(() => import('./views/sickLeaveMessage/SickLeaveForm'))
const SignOutRoute = lazy(() => import('./layout/SignOutRoute'))
const ManualInFinnish = lazy(() => import('./views/layout/ManualInFinnish'))
const ManualInEnglish = lazy(() => import('./views/layout/ManualInEnglish'))

// const JobsSidebar = lazy(() => import('./jobs/JobsSidebar'))
// const LinkAssignmentView = lazy(() => import('./jobs/LinkAssignmentView'))

export const pathRoot = '/'
export const pathLogin = '/login'
export const pathLoginMultifactor = '/login/multifactor'
export const pathUkraineLogin = '/ukraine'
export const pathAdminLogin = '/ad'
export const pathSignOut = '/signout'
export const pathTerms = '/terms'
export const pathTalents = '/talents'
export const pathTalentProfile = '/talent-profile'
export const pathAccount = '/account'
export const pathJobs = '/jobs'
export const pathJobsAdmin = '/jobs-admin'
export const pathJobsList = '/assignments'
export const pathAssignmentsId = '/assignments/:assignmentId'
export const pathClaimsList = '/claims-screen'
export const pathRestoreAuth = '/restoreuser'
export const pathSickLeave = '/sick-leave'
export const assignmentForm = '/find-talents'
// export const assignment = '/assignment/:assignmentId'
export const presentationInfoPage = '/talent'
export const pathAssignmentsInterests = '/jobs/:id/interests/*'
export const pathAdminLandingPage = '/admin-landing-page'
export const pathMarkShift = '/shifts/new'
export const pathOrderShift = '/shifts/order'
export const pathReportShift = '/shifts/report'
export const pathEditShift = '/shifts/edit'
export const pathPastShifts = '/shifts/done'
export const pathUpcomingShifts = '/shifts/upcoming'
export const pathShiftChanges = '/shifts/upcoming?state=changesNeedConfirmation'
export const pathNotices = '/notices'
export const pathOpenShifts = '/open-shifts'
export const pathStaffNews = '/staffnews'
export const pathCalendar = '/calendar/:year/:month/:w?'
export const pathProfile = '/profile'
export const pathChangePassword = '/profile/password'
export const pathChangeEmail = '/profile/email'
export const pathEnableSecondFactor = '/multiFactor'
export const pathNotifications = '/notifications'
export const pathFAQ = '/faq'
export const pathSettings = '/settings'
export const pathWorkBenefits = '/work-benefits'
export const pathLinkedIn = '/auth/linkedin/*'
export const pathTalent = '/talents/talent'
export const pathAccountMigration = '/verify'
export const pathNoAccessToMystaff = '/no-access'
export const pathOpenVacancies = '/open-vacancies'
export const pathSingleOpen = '/s'
export const pathLandingPage = '/l'
export const pathNewOrOld = '/beta-or-old'
export const pathHowToCreateAccount = '/create-account-help'
export const pathSecondFactorError = '/multifactor/e'
export const pathFinnishManual = '/ohje'
export const pathEnglishManual = '/manual'

/**
 * All lazy loaded paths should go through the LazyLoadUtils.retryLazyLoad method
 * to avoid chunk load errors!
 * Use this as an example: lazy: () => LazyLoadUtils.retryLazyLoad(() => import(pathToYourComponentOrScreen))
 */
const routes = [
  {
    path: pathRoot,
    lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./login/LoadingScreen')),
    errorElement: <ErrorPageData />
  },

  {
    element: <Root />,
    errorElement: <ErrorPageData />,
    children: [
      { path: pathSignOut, element: <SignOutRoute /> },
      { path: pathLogin, element: <LoginContent /> },
      { path: pathAdminLogin, element: <AdminLoginScreen /> },
      { path: pathUkraineLogin, element: <LoginContent /> },
      { path: assignmentForm, element: <PublicAssignmentForm /> },
      { path: pathLinkedIn, element: <LoginContent /> },
      { path: pathAccountMigration, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/accountHandling/AccountMigration')) },
      { path: pathNoAccessToMystaff, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/accountHandling/NoMystaffAccess')) },
      { path: pathLandingPage, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./login/LandingPage')) },
      { path: pathNewOrOld, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./login/NewOrOldUi')) },
      { path: pathEnableSecondFactor, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/accountHandling/EnableSecondFactor')) },
      { path: pathLoginMultifactor, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./login/LoginMultiFactor')) },
      { path: pathSecondFactorError, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/accountHandling/multiFactor/MfErrorEmailAndPassword')) },
      { path: pathHowToCreateAccount, element: <LoginHelpDialog /> },
      { path: pathFinnishManual, element: <ManualInFinnish /> },
      { path: pathEnglishManual, element: <ManualInEnglish /> },
      {
        path: pathRoot,
        lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./login/ProtectedRouteStaffPoint')),
        children: [
          {
            path: pathMarkShift,
            lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./employee/shifts/NewShiftPage'))
          },
          {
            path: pathEditShift,
            lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./employee/shifts/EditShiftPage'))
          },
          { path: pathPastShifts, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/shiftList/WorkShiftsData')), loader: WorkShiftsLoader, action: WorkShiftsAction },
          { path: pathUpcomingShifts, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/shiftList/UpcomingShiftsData')), action: UpcomingShiftsAction },
          {
            lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/notices/NoticesContainer')),
            children: [
              {
                path: pathNotifications + '/:skip?/:take?/:from?/:until?/:messageForm?/:onlyUnopened?/:messageType?',
                lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/data/NotificationPage'))
              },
              { path: pathNotices, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/notices/NoticesData')) },
              { path: pathStaffNews, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/notices/StaffNewsData')) }
            ]
          },
          {
            lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/data/OpenContainer')),
            children: [
              {
                path: pathOpenVacancies,
                lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/vacancyCards/VacancyCardsData')),
                exact: true
              },
              {
                path: pathOpenShifts,
                children: [
                  { path: pathOpenShifts + '/:skip/:take', loader: OpenShiftLoader }
                ],
                lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/data/OpenShiftsData')),
                exact: true
              }
            ]

          },
          { path: pathOpenVacancies, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/vacancyCards/VacancyCardsData')) },
          {
            path: pathCalendar,
            lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/Calendar/CalendarData')),
            children: [
              {
                path: 'edit',
                lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./employee/shifts/EditShiftPage'))
              }
            ]

          },
          {

            lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/profilePage/ProfileContainer')),
            children: [
              { path: pathProfile, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/profilePage/ProfilePageData')) },
              { path: pathChangeEmail, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/profilePage/ChangeEmail')) },
              { path: pathChangePassword, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/profilePage/ChangePassword')) }
            ]
          },
          {
            path: pathSingleOpen,
            lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/data/SingleOpenScreenData'))
          },
          {
            path: pathFAQ,
            lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/faq/FaqPage'))
            /* When Page has more than one loader and component, use this method */
            // async lazy () {
            //   const { loader, Component } = await import('./views/faq/FaqPage')
            //   return { loader, Component }
            // }
          },
          { path: pathSettings, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/inputs/SettingsPage')) },
          { path: pathWorkBenefits, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/workBenefits/WorkBenefitsData')) },
          /* Customer */
          {
            path: pathOrderShift,
            lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./customer/shifts/OrderShiftPage'))
          },
          {
            path: pathReportShift,
            lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./customer/shifts/ReportShiftPage'))
          }
        ]

      },
      {
        lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./login/ProtectedRoute')), // Routes that require login credentials
        loader: ProtectedRouteLoader,
        children: [
          { path: pathTalentProfile, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./talents/talent_user/TalentProfileScreen')) },
          { path: pathAccount, lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./user/AccountScreen')) },
          { path: pathSickLeave, element: <SickLeaveForm /> },
          { path: presentationInfoPage, element: <PresentationInfoPage /> },
          {
            // path: pathAdminLandingPage,
            lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./views/layout/RootLayout')),
            children: [
              {
                path: pathTalents,
                lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./talents/TalentsListScreen')),
                loader: TalentsListScreenLoader,
                children: [
                  {
                    path: 'assignments/:assignmentId',
                    lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./jobs/JobAddView')),
                    loader: JobAddViewLoader
                  }
                ]
              },
              {
                path: pathTalent,
                lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./talents/TalentDialogConsultantData')),
                loader: TalentDialogConsultantLoader
              },
              {
                // path: pathAssignmentsInterests
                lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./layout/jobs/JobsSidebar')),
                loader: JobsSidebarLoader,
                exact: true,
                children: [
                  {
                    path: pathAssignmentsInterests,
                    lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./layout/jobs/JobsDataGrid')),
                    loader: JobsDataGridLoader
                  }
                ]
              },
              {
                path: pathJobsList,
                lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./jobs/JobsListData')),
                loader: JobsListLoader,
                action: JobsListAction,
                children: [
                  {
                    path: pathAssignmentsId,
                    lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./jobs/JobAddView')),
                    loader: JobAddViewLoader,
                    children: [
                      {
                        path: 'talents/:ID',
                        lazy: () => LazyLoadUtils.retryLazyLoad(() => import('./talents/TalentDialogConsultantData')),
                        loader: TalentDialogConsultantLoader
                      }
                    ]
                  }
                ]
              },
              { path: pathClaimsList, element: <ClaimsScreen /> },
              { path: pathRestoreAuth, element: <AuthRestoreScreen /> }
              // { path: assignment, element: <LinkAssignmentView.Component />, exact: true },
            ]
          }
        ]
      }
    ]
  }
]

export default routes
