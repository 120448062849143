import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration.js'
import supportedBrowsers from './supportedBrowsers.js'
import handleConsentListener from './hooks/getUCConsents'
const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)
const useServiceWorker = false
window.addEventListener('UC_UI_INITIALIZED', handleConsentListener)
if (supportedBrowsers.test(navigator.userAgent) || process.env.PUBLIC_CHECK_BROWSER !== 'true') {
  root.render(
    <StrictMode><App /></StrictMode>
  )
} else {
  root.render(
    <>
      <h1>This browser isn't supported!</h1>
      <p>
        StaffPoint service requires modern web browser.
        At least the latest versions of Chrome, Edge and Firefox are supported.
      </p>
      <ul>
        <li><a href='https://www.google.com/chrome'>Chrome</a></li>
        <li><a href='https://www.microsoft.com/en-us/edge/download?form=MA13FJ'>Edge</a></li>
        <li><a href='https://www.mozilla.org/en-US/firefox/new'>Firefox</a></li>
      </ul>
    </>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

if (useServiceWorker) serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
