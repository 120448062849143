export const COLORS = {
  white: '#FFF',
  red: '#f44336',
  error: '#972626',
  success: '#8EBF90',
  link: '#536dfe',
  light: '#f8f8f8',
  lightBorder: '#e0e0e0',
  lightText: '#aaa',
  label: 'rgba(0, 0, 0, 0.54)',
  blue: '#1483C2',
  orange: '#C9905F',
  purple: '#867299',
  purpleHover: '#664486',
  green: '#6F9482',
  greenHover: '#87AA9A',
  greenDisabled: '#9CC2B0',
  black: '#000',
  grey: '#6E6E73',
  lightGrey: '#DEDEDE'
}

export const palette = {
  primary: {
    main: COLORS.purple, // purple
    contrastText: COLORS.white // white
  },
  secondary: {
    main: '#7CB2B3', // light green
    contrastText: '#212B29', // dark
    dark: '#844FB8', // dark violet
    light: '#EFEDE9' // light white
  },
  error: {
    main: COLORS.red,
    contrastText: '#EFEDE9' // light white
  }
}

export const talentPoint = {
  palette: {
    primary: {
      main: COLORS.purple,
      contrastText: COLORS.white
    },
    secondary: {
      main: COLORS.green,
      contrastText: COLORS.white
    },
    success: {
      main: COLORS.success,
      contrastText: COLORS.white
    },
    error: {
      main: COLORS.error,
      contrastText: COLORS.white
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(',')
  },
  components: {
    // Name of the component
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        input: {
          // Some CSS
          fontSize: 14
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 360,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
}
