import FirestoreTopCollection from '../google/firestore/FirestoreTopCollection'

class IndustryRepository extends FirestoreTopCollection {
  constructor () {
    super('industries')
  }
}

const expertiseRep = new IndustryRepository()
export default expertiseRep
