import FirestoreTopCollection from '../google/firestore/FirestoreTopCollection'

class JobsRepository extends FirestoreTopCollection {
  constructor () {
    super('jobs')
  }

  /**
   * Converts Job object into JS object and adds it to database
   * @param {Job[]} data Data to be added to the database
   * @returns {string} Id of the new database document
   */
  async addNewJob (data) {
    const clonedData = JSON.parse(JSON.stringify(data))
    clonedData.internalContact = this._convertUserArray(clonedData.internalContact)
    clonedData.kam = this._convertUserArray(clonedData.kam)
    clonedData.recruitmentConsultant = this._convertUserArray(clonedData.recruitmentConsultant)
    clonedData.presentedTalents = this._convertUserArray(clonedData.presentedTalents)
    clonedData.presentableTalents = this._convertUserArray(clonedData.presentableTalents)
    delete clonedData.jobComment
    const docRef = await this.add(clonedData)
    return docRef.id
  }

  async addNewPublicAssignmentForm (newJob) {
    const data = JSON.parse(JSON.stringify(newJob))

    const dataJson = {
      ...data,
      dailyWorkTime: JSON.parse(data.dailyWorkTime),
      continuityOfWork: JSON.parse(data.continuityOfWork),
      workingTime: JSON.parse(data.workingTime),
      languages: JSON.parse(data.languages)
    }

    const docRef = await this.add(dataJson)
    console.log(docRef.id)
    return docRef.id
  }

  /**
   * Converts array of Objects inside Job object into array of string IDs
   * @param {Object[]} users Data that contains field to be converted
   * @returns {string[] | null} Array of ids
   */
  _convertUserArray (users) {
    if (!users) return null
    const newUsers = []
    for (const contact of users) {
      newUsers.push(contact?.id)
    }
    return newUsers
  }

  /**
   * Converts Job object into JS object and adds it to the database using existing ID
   * @param {Job[]} data Data to be added to the database
   */
  async editJob (data) {
    const clonedData = JSON.parse(JSON.stringify(data))
    clonedData.internalContact = this._convertUserArray(clonedData.internalContact)
    clonedData.kam = this._convertUserArray(clonedData.kam)
    clonedData.recruitmentConsultant = this._convertUserArray(clonedData.recruitmentConsultant)
    clonedData.presentedTalents = this._convertUserArray(clonedData.presentedTalents)
    clonedData.presentableTalents = this._convertUserArray(clonedData.presentableTalents)
    const id = clonedData.id
    delete clonedData.id
    delete clonedData.jobComment
    this.setById(id, clonedData)
  }

  /**
   * Deletes job from database but leaves audit logs
   * @param {string} id ID of the job to be deleted
   */
  async deleteJob (id) {
    this.delete(id)
  }

  /**
   * Adds document to jobComment subcollection
   * @param {string} id ID of the job document
   * @param {string} comment Comment to be added
   */
  async addJobComment (id, comment) {
    await this.setById(`${id}/jobComment/comment`, { comment })
  }

  /**
   * Gets all jobs and their respective jobComments
   * @returns {Object[] | []}
   */
  async getAllJobs () {
    return await this.getAllWithSubcollections(['jobComment'])
  }

  /**
   * Gets the details of the assignment for talent view
   * @param {string} idOfJob id of assignment
   * @returns {{ name: string, location: string, company: string } | null} details of assignment
   */
  async getJobDetails (idOfJob) {
    const job = await this.getById(idOfJob)
    if (!job) return null
    return { name: job.jobHeading, location: job.location, company: job.company }
  }

  /**
   * Fetches a single assignment and patches in the jobComment if required
   * @param id Id of assignment to be fetched
   * @returns {Object} assignment object
   */
  async getJob (id) {
    const comment = await this.getById(`${id}/jobComment/comment`)
    const job = await this.getById(id)
    if (comment?.comment) {
      job.jobComment = comment.comment
    }
    return job
  }
}

const jobRep = new JobsRepository()
export default jobRep
