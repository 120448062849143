import {
  collection,
  getDocs,
  connectFirestoreEmulator,
  initializeFirestore
} from 'firebase/firestore'
import { app } from '../firebaseApp'
import production from '../../utils/production'

export function toDocument (documentSnapshot) {
  const data = documentSnapshot.data()
  return {
    id: documentSnapshot.id,
    ...data
  }
}

export async function getDocuments (query) {
  const querySnapshot = await getDocs(query).catch(reason => console.log(reason))
  const documents = []
  querySnapshot.forEach(documentSnapshot => documents.push(toDocument(documentSnapshot)))
  return documents
}

export async function getDocumentsWithSubcollections (query, subcollections) {
  const querySnapshot = await getDocs(query)
  const documents = []
  for (const documentSnapshot of querySnapshot.docs) {
    const document = toDocument(documentSnapshot)
    for (const collectionId of subcollections) {
      document[collectionId] = await getDocuments(collection(documentSnapshot.ref, collectionId))
    }
    documents.push(document)
  }
  return documents
}

export const db = initializeFirestore(app, { experimentalForceLongPolling: true })
// Everytime you run `npm start` or `npm test`, you connect to emulator db
if (!production && process.env.PUBLIC_USE_EMU !== 'false') {
  const emulatorLocation = window.location.href.split('/')[2].replace(':3000', '')
  connectFirestoreEmulator(db, emulatorLocation, 8080)
}
