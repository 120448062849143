import i18n from 'i18next'
// import en from './i18n/en.js'
import { Settings } from 'luxon'
import { initReactI18next } from 'react-i18next'
import en from './i18n/default/en.json'
import uk from './i18n/default/uk.json'
import ru from './i18n/default/ru.json'
import fi from './i18n/default/fi.json'
import talentpointEn from './i18n/talentpoint/en.json'
import talentpointUk from './i18n/talentpoint/uk.json'
import talentpointRu from './i18n/talentpoint/ru.json'
import talentpointFi from './i18n/talentpoint/fi.json'
import webEn from './i18n/web/en.json'
import webUk from './i18n/web/uk.json'
import webRu from './i18n/web/ru.json'
import webFi from './i18n/web/fi.json'
import { createContext } from 'react'
export const enUS = 'en'
export const fiFI = 'fi'
export const ruRU = 'ru'
export const svSE = 'sv'
export const ukUA = 'uk'
export const supportedLanguages = Object.freeze([enUS, fiFI, ukUA, ruRU, svSE])
const resources = {
  en: {
    ...en,
    ...webEn
  },
  uk: {
    ...uk,
    ...webUk

  },
  ru: {
    ...ru,
    ...webRu

  },
  fi: {
    ...fi,
    ...webFi
  }
}

/**
 * Internationalization and localization handler
 *
 * See:
 * - (Intl)[
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
 * ]
 * - (Browser language detector)[
 * https://github.com/i18next/i18next-browser-languageDetector]
 */
export default class Locale {
  /**
   * @class
   * @param {Object} lang A state
   * @param {Object} setLang A setter of a state
   * @param {{talentPoint: boolean, springer: boolean, myStaff: boolean}} projects An object of booleans defining what language files to load
   * @param {Object} i18Options Options applied to i18n initialization
   * @param {string} dateFormat A date format that can be used to format date objects as strings.
   * @param {string} timeFormat A time format that can be used to format date objects as time strings.
   * @param {{lang: string, currency:string}} currencyOptions An object containing the wanted setting for the currency formater. Defaults to the Finnish format with Euros
   * @param {{locale: string, currency:string}} hoursOptions An object containing the wanted setting for the hours formater. Defaults to the Finnish format

   * See [here](https://moment.github.io/luxon/#/formatting?id=table-of-tokens) for a list of options. Defaults to the Finnish format without padding.
 */
  #currencyFormat
  #hoursFormat
  constructor (
    lang,
    setLang,
    projects = { talentPoint: false, springer: false, myStaff: false },
    i18Options = {},
    dateFormat = 'd.M.y',
    timeFormat = 'H.mm',
    currencyOptions = { lang: 'fi', currency: 'EUR' },
    hoursOptions = { locale: lang }) {
    if (projects.talentPoint) {
      Object.assign(resources.en, talentpointEn)
      Object.assign(resources.uk, talentpointUk)
      Object.assign(resources.ru, talentpointRu)
      Object.assign(resources.fi, talentpointFi)
    }
    i18n
      .use(initReactI18next)
      .init({
        fallbackLng: enUS,
        resources,
        lng: lang,
        ...i18Options
      })
    this.i18 = i18n
    this.language = lang
    Settings.defaultLocale = lang
    this.setLang = setLang
    this.dateFormat = dateFormat
    this.#currencyFormat = new Intl.NumberFormat(currencyOptions.lang, {
      style: 'currency',
      currency: currencyOptions.currency
    })
    this.#hoursFormat = new Intl.NumberFormat(hoursOptions.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'unit',
      unit: 'hour'
    })
    this.timeFormat = timeFormat
    window?.document?.getElementsByTagName('html')[0].setAttribute('lang', lang)
  }

  /**
   * Change the app's language to the specified one
   * @param {String} locale The language as a two character string
   */
  changeLanguage (locale) {
    if (!supportedLanguages.includes(locale)) {
      throw new Error(`Unsupported locale '${locale}'!`)
    }
    Settings.defaultLocale = locale
    this.i18.changeLanguage(locale)
    this.language = locale
    window?.localStorage?.setItem('lang', locale)
    this.setLang(locale)
    window?.document?.getElementsByTagName('html')[0].setAttribute('lang', locale)
  }

  /**
 * Formats a number to a currency string
 * @param {Number} currency number to format
 * @returns A formated currency string
 */
  currencyFormater (currency) {
    return this.#currencyFormat.format(currency)
  }

  /**
* Formats a hours to a string
* @param {Number} hours hours to format
* @returns A formated hours string
*/
  hoursFormater (hours) {
    return this.#hoursFormat.format(hours)
  }

  /**
   * Use translations from a specified namespace
   * @param {String} componentName Name space, correlates with component names
   * @returns {newT} a i18next t function used to translate strings
   */
  useT (componentName) {
    const t = this.i18.getFixedT(null, componentName)
    /**
     * Get a translation for a given key
     * @param {String} key
     * @returns {String} Localized string
     */
    const newT = (key) => {
      const loc = t(key)
      // if (key !== '' && key === loc) console.warn('Unlocalized key found:', key, componentName ? 'in ' + componentName : '')
      return loc
    }
    return newT
  }

  get lang () {
    return this.language
  }

  /**
   * Get current locale.
   *
   * @returns {Locale} The class instance.
   */
  static get () {
    return this
  }
}

export const localeContext = createContext(new Locale('fi', () => {}))

export function createKey (namespace, key) {
  return `${namespace}:${key}`
}

/**
 * Depricated! Use useContext(localeContext) to get the locale class
 *
 * Then use the locale classes useLang method instead
 */
export function useLang (param = '') {
  throw new Error('The useLang method is depricated! Use useContext(localeContext) to get the locale class instance. ' + param)
}
/**
 * Depricated! Use useContext(localeContext) to get the locale class.
 *
 * Then use the locale classes useT method instead
 */
export function useT (param = '') {
  throw new Error('The useT method is depricated! Use useContext(localeContext) to get the locale class instance. ' + param)
}
