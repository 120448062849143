import {
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
  getRedirectResult,
  setPersistence,
  signInWithCustomToken,
  signInWithRedirect,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  getIdToken
} from 'firebase/auth'
import AzureAdAuthProvider from '../../microsoft/AzureAdAuthProvider'
import LinkedInAuthProvider from '../../microsoft/LinkedInAuthProvider'
import production from '../../utils/production'
import NotRegisteredUser from './NotRegisteredUser'
import UserAccount from './UserAccount'
import { app } from '../firebaseApp'

export const auth = getAuth(app)
if (!production && process.env.PUBLIC_USE_EMU !== 'false') {
  const emulatorLocation = window.location.href.replace(':3000', ':9099')
  connectAuthEmulator(auth, emulatorLocation, { disableWarnings: true })
}
const persistenceInit = setPersistence(auth, browserLocalPersistence)

// export const admins = []
export const linkedIn = new LinkedInAuthProvider('770jg175fudsgt')
export const staffPointAd = new AzureAdAuthProvider(
  '5303131b-40b3-4f89-9b09-3a4ad7bc2275'
)
export const google = new GoogleAuthProvider()
export const facebook = new FacebookAuthProvider()
/**
 * Get user from federated authentication provider's redirect result.
 * @return {Promise<import('firebase/auth').User>|null} Authenticated user or
 * null.
 */
async function getFederatedUser () {
  const credentials = await getRedirectResult(auth)
  if (credentials) {
    console.debug('Federated credentials', credentials)
    return credentials.user
  }
}

/**
 * Register and authenticate LinkedIn user if authorization code is found from
 * redirect result.
 * @return {Promise<NotRegisteredUser|import('firebase/auth').User>} Authenticated user or
 * null.
 */
async function getLinkedInUser () {
  const code = linkedIn.getAuthorizationCode()
  if (code) {
    const userOrtoken = await linkedIn.signInWithAuthorizationCode(code)
    if (userOrtoken instanceof NotRegisteredUser) {
      console.log(userOrtoken)
      return userOrtoken
    }
    const credentials = await signInWithCustomToken(auth, userOrtoken)
    console.debug('LinkedIn credentials', credentials)
    return credentials.user
  }
}

/**
 * Search authenticated user in local storage.
 * @returns {Promise<import('firebase/auth').User>|null} Authenticated user or
 * null.
 */
async function getLocalUser () {
  await persistenceInit
  const user = auth.currentUser
  if (user) {
    console.debug('Local user', user)
    return user
  }
}

/**
 * Get current user if authenticated.
 * @return {Promise<UserAccount|null} Authenticated user or null.
 */
export async function getUser () {
  // Check local storage
  let user = await getLocalUser()
  if (user) return new UserAccount(user)

  // Check custom provider's redirect result.
  user = await getLinkedInUser()
  if (user) return new UserAccount(user)

  // Check federated provider's redirect result:
  // - Microsoft (StaffPoint AD)
  user = await getFederatedUser()
  if (user) return new UserAccount(user)

  // Leave in unauthenticated state
  return null
}

export async function signInWithFacebook () {
  await signInWithRedirect(auth, facebook)
}

export async function signInWithGoogle () {
  await signInWithRedirect(auth, google)
}

export async function signInWithLinkedIn () {
  linkedIn.signInWithRedirect()
}

export async function signInWithStaffPointAd () {
  await signInWithRedirect(auth, staffPointAd)
}

export async function signOutUser () {
  await signOut(auth)
}
export async function refreshToken () {
  const user = await getLocalUser()
  await getIdToken(user, true)
}
