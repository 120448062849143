import FirestoreTopCollection from '../google/firestore/FirestoreTopCollection'

class CommentsRepository extends FirestoreTopCollection {
  constructor () {
    super('comments')
  }

  async addComment (_id, _comment) {
    const addComment = {
      comment: _comment
    }
    return await this.setById(_id, addComment)
  }

  async getComment (id) {
    return await this.getById(id)
  }

  async deleteComment (id) {
    try {
      await this.delete(id)
    } catch (error) {
      console.warn('Could not delete comment')
    }
  }
}

const commentsRep = new CommentsRepository()

export default commentsRep
