import AdoreJobRow from './AdoreJobRow.js'
/**
 * * There is single document of this located at /views/jobs
 * */
export default class Jobs {
  #cities
  #counties
  #jobs
  #recruiters

  constructor (data) {
    this.#cities = data.cities || []
    this.#counties = data.counties || []
    this.#recruiters = data.recruiters || []
    this.#jobs = Object.entries(data.jobs).map(([id, data]) => new AdoreJobRow(id, data))
  }

  get cities () { return this.#cities }
  get counties () { return this.#counties }
  get jobs () { return this.#jobs }
  get recruiters () { return this.#recruiters }

  filterJobs (title, city, county, recruiter) {
    let filtered = this.#jobs
    if (title) {
      const searchTitle = `${title}`.trim().toLowerCase()
      filtered = filtered.filter(j => j.hasTitle(searchTitle))
    }
    if (city) {
      filtered = filtered.filter(j => j.hasCity(city))
    }
    if (county) {
      filtered = filtered.filter(j => j.hasCounty(county))
    }
    if (recruiter) {
      filtered = filtered.filter(j => j.recruiter === recruiter)
    }
    return filtered
  }

  getJobNameById (id) {
    const job = this.#jobs.find(j => j.id === id)
    return job ? job.title : ''
  }
}
