import FirebaseFunctionGroup from '../google/functions/FirebaseFunctionGroup'

export default class LinkedInFunctions extends FirebaseFunctionGroup {
  constructor () {
    super('linkedIn', 'auth')
  }

  /**
   * Exchange authorization code to access token, create user in Firebase
   * Authentication if not exists and authenticate against it.
   * @param {string} code Authorization code from LinkedIn.
   * @param {string} redirect Redirect URI that was used when requested
   * the authorization code.
   * @return {Promise<{email: string|undefined, token: string}>} Promise of
   * object with email address and LinkedIn access token for new
   * user. If user exists only Firebase access token returned.
   */
  async signIn (code, redirect) {
    const result = await this._putToData('signIn', { code, redirect })
    console.debug('sign in result', result)
    return result
  }
}
