/**
 * Build encoded URL string.
 * @param {string} base Base URL
 * @param {string} path Path which defaults to empty string
 * @param {object} params Query parameters.
 * @returns {string} URL string.
 */
export function buildUrl (base, path = '', params = {}) {
  const url = new URL(path, base)
  const searchParams = url.searchParams
  Object.entries(params).forEach(([name, value]) => searchParams.append(name, value))
  return url.href
}
