import { defer, redirect } from 'react-router-dom'
import { pathTalents } from '../routes'
import { getTalentWithOrderedDates } from '../utils/talent'
import occupationRep from './occupationRep'
import { loadAvatar } from './TalentAvatarRep'
import { loadCV } from './TalentCvRep'
import talentRep from './talentRep'

export default async function TalentDialogConsultantLoader ({ request }) {
  const id = new URL(request?.url)?.searchParams?.get('id')
  if (!id) {
    console.log('Invalid ID')
    return redirect(pathTalents)
  }
  const talentData = talentDataLoader(id)
  const talentHistoryData = talentHistoryDataLoader(id)

  return defer({ talentData, talentHistoryData })
}

async function talentDataLoader (id) {
  const res = await talentRep.getTalent(id)
  if (!res) {
    console.log('Invalid ID')
    return redirect(pathTalents)
  }
  const talentName = res.firstName + ' ' + res.lastName
  const selectedLoc = await (window.localStorage.getItem('lang')) ? window.localStorage.getItem('lang') : 'en'
  const jobsObject = await occupationRep.getAllInSingleLang(selectedLoc)
  const jobs = res.jobs
  const organizedTalent = await getTalentWithOrderedDates(res)
  const talentAudits = await talentRep.getAudit(id)
  let cv = null
  if (res.fileCV) cv = await loadCV(id, res.fileCV)
  const avatar = await loadAvatar(id, res.avatar)
  return { talentName, jobsObject, jobs, organizedTalent, talentAudits, cv, avatar }
}

async function talentHistoryDataLoader (id) {
  const educations = await talentRep.getTalentEducations(id)
  const workHistory = await talentRep.getTalentWorkHistory(id)
  const workReferences = await talentRep.getTalentWorkReferences(id)
  return { educations, workHistory, workReferences }
}
