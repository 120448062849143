import mobileApi from '../../mystaff/mobileApi'

export async function UpcomingShiftsAction ({ request }) {
  const formData = await request.formData()
  const requestString = new window.URLSearchParams(formData).toString()
  const response = await mobileApi.getUpcomingShifts(requestString)
  if (response?.status === 200) {
    return { ...response, data: response.data }
  } else return response
}
