import FirestoreTopCollection from '../google/firestore/FirestoreTopCollection'

class OccupationRepository extends FirestoreTopCollection {
  constructor () {
    super('occupations')
  }

  async getOccupationIDs (selectedLoc = 'en') {
    const result = await this.getAll()
    const simplifiedResult = result.map(job => {
      const simpleJob = [job.id]

      console.log(job)

      return simpleJob
    })
    return simplifiedResult
  }

  async getAllInSingleLang (lang = 'en') {
    const documents = await this.getAll()
    const occupations = {}
    documents.forEach(doc => { occupations[doc.id] = doc[lang] })
    return occupations
  }

  async getOccupationLoc (jobIDs, selectedLoc = 'en') {
    let lang = ''
    console.log(jobIDs)
    await jobIDs.forEach(async jobID => {
      console.log(jobID)
      const result = await this.getById(jobID)
      console.log(result)
      switch (selectedLoc) {
        case 'ru':
          lang += result.ru + ' '
          break
        case 'uk':
          lang += result.uk + ' '
          break
        case 'fi':
          lang += result.fi + ' '
          break
        default:
          lang += result.en
          break
      }
      console.log(lang)
    })
    console.log(lang)
    return lang
  }
}

const occupationRep = new OccupationRepository()
export default occupationRep
