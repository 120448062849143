import viewsRep from '../talents/viewsRep'

export const fetchConsultants = async () => {
  // consultants
  const consultantsObject = await viewsRep.getAllConsultants()
  delete consultantsObject.id
  // Converts Object into array of objects
  return Object.entries(consultantsObject).map(consultant => ({ id: consultant[0], name: consultant[1] }))
}

export const fetchTalents = async () => {
  // talents
  const talentsObject = await viewsRep.getAllViewsTalents()
  delete talentsObject.id
  // Converts Object into array of objects
  return Object.entries(talentsObject).map(talent => ({ id: talent[0], name: talent[1] }))
}

export const fetchJobTitlesEn = async () => {
  // jobTitlesEn
  return await viewsRep.getJobTitlesTranslations()
}
