import { styled, useTheme } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'

export default function BackgroundPage (props) {
  const theme = useTheme()

  let background
  if (props.talentpoint) {
    background = '/tpDarken.jpg'
  } else if (props.help) {
    background = '/Winter.svg'
  } else {
    /* Potential login page backgrounds featuring some comments */
    // background = '/SP_bg_purple.png' // ihan jees, kokeillaan eri väreillä?
    // background = '/SP_bg_greenballs.png' // paras tähän mennessä, hillitty määrä palloja
    // background = '/SP_bg_multicolor.png' // vapputeema :D kiva
    // background = '/SP_bg_mint.png' // ei huono
    // background = '/SP_bg_lightpurple.png'// talvinen, mennään tällä
    background = '/Winter.svg' // based on SP_bg_lightpurple.png
  }
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <StyledPage background={background} sx={props.sx} mobile={`${mediumScreen}`}>
      {props.children}
    </StyledPage>
  )
}

const StyledPage = styled('div')((props) => ({
  flexGrow: 1,
  zIndex: 2,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  '&::after': {
    content: '\'\'',
    zIndex: 1,
    position: 'fixed',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'right top',
    backgroundImage: props.background && 'url(' + props.background + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
}))
