import { DateTime } from 'luxon'

/**
 * Recruitment assignment
 */
export default class Job {
  /**
   * Construct Job
   * @param {string} id ID generated by datastore
   * @param {object} doc Job document
   * @param {string} doc.customer Company ID
   * @param {string[]} doc.contacts Company contacts
   * @param {string} doc.title Job title
   * @param {string} doc.jobHeading Job heading
   * @param {string} doc.description Job description
   * @param {string} doc.location Job municipality
   * @param {[{id: string, name: string, hiredStatus: string}]} doc.presentedTalents Presented talents
   * @param {string[]} doc.presentableTalents To be presented talents
   * @param {string} doc.openPositions Amount of open positions
   * @param {string} doc.skillsRequired Required skills and/or education
   * @param {object} doc.languages Required languages for the job
   * @param {string} doc.workType The type of job (full-time, nightshift etc.)
   * @param {string} doc.salary Wage or salary of the job
   * @param {string} doc.assignmentComplete The state of assignment
   * @param {string} doc.serviceType The type of service (staffing or recruitment)
   * @param {string[]} doc.internalContact Consultant
   * @param {string} doc.otherConsiderations Other info
   * @param {boolean} doc.signedContract Whether customer has signed a contract
   * @param {string[]} doc.kam Consultant
   * @param {string[]} doc.recruitmentConsultant Consultant
   * @param {string} doc.customerManagementContact
   * @param {boolean} doc.contractStatus Whether contract is active
   * @param {string} doc.updated Date when job was updated or created
   * @param {string} doc.created Date when job was created
   * @param {string[]} doc.continuityOfWork Continuity of work
   * @param {string[]} doc.dailyWorkTime Weekly hours for the job
   * @param {string[]} doc.workingTime Working time for the job
   * @param {object} doc.customerRecruiter  Required customerRecruiter for the job
   */
  constructor (doc = {}, talents, consultants) {
    this.id = doc.id || null
    this.company = doc.company || null
    this.contacts = doc.contacts || []
    this.title = doc.title || null
    this.jobHeading = doc.jobHeading || null
    this.priority = doc.priority || null
    this.unit = doc.unit || null
    this.description = doc.description || null
    this.location = doc.location || null
    this.presentedTalents = this.parseTalents(doc.presentedTalents, talents)
    this.presentableTalents = this.parseTalents(doc.presentableTalents, talents)
    this.openPositions = doc.openPositions || null
    this.skillsRequired = doc.skillsRequired || null
    this.languages = doc.languages || null
    this.workType = doc.workType || null
    this.salary = doc.salary || null
    this.assignmentComplete = doc.assignmentComplete || null
    this.jobComment = doc.jobComment || null
    this.serviceType = doc.serviceType || null
    this.internalContact = this.parseConsultants(doc.internalContact, consultants)
    this.otherConsiderations = doc.otherConsiderations || null
    this.signedContract = typeof doc.signedContract === 'undefined' ? null : doc.signedContract
    this.kam = this.parseConsultants(doc.kam, consultants)
    this.recruitmentConsultant = this.parseConsultants(doc.recruitmentConsultant, consultants)
    this.customerManagementContact = doc.customerManagementContact || null
    this.contractStatus = typeof doc.contractStatus === 'undefined' ? null : doc.contractStatus
    this.updated = doc.updated || null
    this.created = doc.created || null
    this.customerRecruiter = doc.customerRecruiter || { phone: null, email: null }
    this.continuityOfWork = doc.continuityOfWork || null
    this.dailyWorkTime = doc.dailyWorkTime || null
    this.workingTime = doc.workingTime || null
  }

  /**
   * Handles the state of passed consultants case by case basis
   * @param {String[] | ?string | Object[]} _consultantsArray Array of ids
   * @returns {Object[]} Array of object with id and name
   */
  parseConsultants (_consultantsArray, consultants) {
    if (Array.isArray(_consultantsArray) && typeof _consultantsArray[0] === 'object') return _consultantsArray
    else if (!_consultantsArray) return null
    else if (typeof _consultantsArray === 'string') return Array.from(_consultantsArray)
    else return consultants.filter(consultant => _consultantsArray.includes(consultant.id)).map(consultant => ({ id: consultant.id, name: consultant.name }))
  }

  /**
   * Handles the state of passed talents case by case basis
   * @param {String[] | ?string | Object[]} _talentsArray Array of ids
   * @param {Object[]} talents Talent names and Ids
   * @returns {Object[]} Array of object with id and name
   */
  parseTalents (_talentsArray, talents) {
    if (Array.isArray(_talentsArray) && typeof _talentsArray[0] === 'object') return _talentsArray
    else if (!_talentsArray) return null
    else if (typeof _talentsArray === 'string') return Array.from(_talentsArray)
    else return talents.filter(talent => _talentsArray.includes(talent.id)).map(talent => ({ id: talent.id, name: talent.name }))
  }

  toString () {
    return `#${this.id} - ${this.title}`
  }

  /**
   * Sets current date as timestamp for the job
   */
  createTimestamp () {
    this.updated = DateTime.now().toISODate()
  }
}
