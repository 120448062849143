import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocFromCache,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import { isString } from 'lodash'
import { DateTime } from 'luxon'
import { db, getDocuments, getDocumentsWithSubcollections } from './firebaseFirestore'
import { getUser } from '../auth/firebaseAuth'

/**
 * Audit sub-collection ID.
 */
const audit = 'audit'

/**
 * Top-level collection repository in Google Firebase Firestore.
 */
export default class FirestoreTopCollection {
  /**
   * Constructor
   *
   * @param {string} name Collection name
   * @param {boolean} auditable Use audit sub-collection
   */
  constructor (name, auditable = true) {
    this.collection = collection(db, name)
    this._auditable = auditable
    this._name = name
  }

  /**
   * Add audit entry for document
   * @param {import('../auth/UserAccount').default} user User which changed data
   * @param {import('firebase/firestore').DocumentReference} ref Document reference which is audited
   * @param {object} doc Audited data
   */
  async _addAudit (user, ref, document) {
    if (!this._auditable) return
    const at = DateTime.now()
    const auditDocRef = doc(this._audit(ref), at.toMillis().toString())
    const audit = {
      userId: user.id ? user.id : user.updated.id,
      userName: user.name ? user.name : user.updated.name,
      ...document
    }
    // Delete irrelevant data from audit
    delete audit.added
    delete audit.id
    await setDoc(auditDocRef, audit).catch(error => console.warn(error))
    console.log(`${this._name}/${ref.id} audited at ${at}`, audit)
  }

  /**
   * Get reference to document's audit sub-collection.
   * @param {string|import('firebase/firestore').DocumentReference} idOrRef Document ID or it's reference
   * @returns {import('firebase/firestore').CollectionReference} Audit collection reference
   */
  _audit (idOrRef) {
    return collection(this._doc(idOrRef), audit)
  }

  /**
   * Add a new document to this collection with the given data, assigning it a document ID automatically.
   * Sets also 'added' timestamp.
   *
   * @param {object} data Document data
   * @returns {import('firebase/firestore').DocumentReference} Document reference which has the newly created ID.
   */
  async add (data) {
    if (!data.added) data.added = serverTimestamp()
    delete data.id
    /* To do: add data.creatorId here */
    return await addDoc(this.collection, data)
  }

  async delete (id) {
    return await deleteDoc(doc(this.collection, id))
  }

  _doc (idOrRef) {
    return isString(idOrRef) ? this.docRefById(idOrRef) : idOrRef
  }

  docRefByDto (dto) {
    return this.docRefById(dto.id)
  }

  /**
   * Gets a `DocumentReference` instance that refers to a document within this collection.
   * If no ID is specified, an automatically-generated unique ID will be used for the returned `DocumentReference`.
   *
   * @param {string} id Document ID
   * @returns {import('firebase/firestore').DocumentReference} Document reference.
   */
  docRefById (id) {
    return doc(this.collection, id)
  }

  async existsById (id) {
    return await this.existsByRef(this.docRefById(id))
  }

  async existsByRef (ref) {
    const snap = await this.getSnapByRef(ref)
    return snap.exists()
  }

  /**
   * Query all documents in this collection.
   *
   * @returns {Promise<Array<object>>} Array of document data with id.
   */
  async getAll () {
    return await getDocuments(this.collection)
  }

  /**
   * Query all documents by descending `added` order.
   */
  async getAllOrderedByAddedFirst () {
    return await this.query(orderBy('added', 'desc'))
  }

  async getAllWithAudit () {
    return await this.getAllWithSubcollections([audit])
  }

  async getAllWithSubcollections (subcollections) {
    return await getDocumentsWithSubcollections(this.collection, subcollections)
  }

  async getAudit (idOrRef) {
    return await getDocuments(this._audit(idOrRef))
  }

  async getById (id) {
    return id ? this.getByRef(this.docRefById(id)) : null
  }

  async getByRef (ref) {
    const snap = await this.getSnapByRef(ref)
    if (snap.exists()) {
      const data = snap.data()
      return {
        id: ref.id,
        ...data
      }
    }
    return null
  }

  async getSnapByRef (ref) {
    try {
      return await getDocFromCache(ref)
    } catch (error) {
      return await getDoc(ref)
    }
  }

  /**
   *
   * @param {UserAccount} user User that merges data
   * @param {object} doc Document data to merge
   * @param {string|import('firebase/firestore').DocumentReference} idOrRef Document ID or it's reference
   */
  async _merge (user, doc, idOrRef = doc.id) {
    if (!doc.id && idOrRef === doc.id) idOrRef = doc.updated.id
    const ref = this._doc(idOrRef)
    delete doc.id
    await setDoc(ref, doc, { merge: true })
    await this._addAudit(user, ref, doc)
  }

  async mergeByDto (dto) {
    await this.setByDto(dto, true)
  }

  async mergeById (id, data) {
    await this.setById(id, data, true)
  }

  async mergeByRef (ref, data) {
    await this.setByRef(ref, data, true)
  }

  async _myDocRef () {
    const user = await getUser()
    return user ? this.docRefById(user.id) : null
  }

  async query (...constraints) {
    return await getDocuments(query(this.collection, ...constraints))
  }

  async queryOne () {
    throw new Error('Not implemented')
  }

  async setByDto (dto, merge = false) {
    const id = dto.id
    delete dto.id
    await this.setById(id, dto, merge)
  }

  async setById (id, data, merge = false) {
    return await this.setByRef(this.docRefById(id), data, merge)
  }

  async setByRef (ref, data, merge = false) {
    if (!await this.existsByRef(ref) && !data.added) {
      data.added = serverTimestamp()
    }
    const user = await getUser()
    if (user) return await this._merge(user, data, ref)
    return await setDoc(ref, data, { merge })
  }

  async updateById (id, data) {
    await this.updateByRef(this.docRefById(id), data)
  }

  /**
       * Updates the given fields in referenced document.
       *
       * @param {import('firebase/firestore').DocumentReference} ref Document reference to update.
       * @param {object} data Fields and their values to update in document.
       */
  async updateByRef (ref, data) {
    const user = await getUser()
    this._addAudit(user, ref, data)
    await updateDoc(ref, data)
  }
}
