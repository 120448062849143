import { DateTime } from 'luxon'

export const localeMap = {
  en: DateTime.now().setLocale('en').locale,
  fi: DateTime.now().setLocale('fi').locale
}

export const maskMap = {
  en: '__/__/____',
  fi: '__.__.____'
}

export const getDateTimeForView = (timestamp) => {
  const myDateTime = DateTime.fromSeconds(timestamp.seconds)
  const myDateTimeISO = myDateTime.toISO()
  return timestamp ? DateTime.fromISO(myDateTimeISO).toFormat('dd.LL.yyyy HH:mm') : ''
}

// export const toMonthYearDate = (stringDate) => stringDate ? parse(stringDate, 'yyyy-MM', new Date()) : null

// export const toDate = (stringDate) => stringDate ? parse(stringDate, 'dd.MM.yyyy', new Date()) : null

// export const getDateForView = (timestamp) => timestamp ? format(timestamp.toDate(), 'yyyy-MM') : ''

// export const getDateTimeForView = (timestamp) => timestamp ? format(timestamp.toDate(), 'dd.MM.yyyy HH:mm') : ''

/* The values are expected to be Firestore timestamps */
export const sortByDateDesc = (array, key) => {
  return array.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return getFirestoreTimeStampAsDate(b[key]) - getFirestoreTimeStampAsDate(a[key])
  })
}

export const getFirestoreTimeStampAsDate = (timestamp) => timestamp ? timestamp.toDate() : null
