/**
 AdoreJob class
 * @param {Object} data
 * @param {string} data.n Job title.
 * @param {string} data.c Business unit name.
 * @param {string} data.b Business unit description.
 * @param {string} data.a Apply link suffix after 'https://staffpoint.talentadore.com/apply/'.
 * @param {string} data.d Description HTML content.
 * @param {string} data.u Updated Timestamp.
 * @param {string} data.s Start date ISO string.
 * @param {string} data.d Due date ISO string.
 * @param {string} data.i Location address?
 * @param {string} data.e Counties array.
 * @param {string} data.c Cities array.
 * @param {string} data.t TalentAdore tags.
 * @param {Object} data.o Occupation (profession title)
 * @param {string} data.o.i ISCO integer number
 * @param {string} data.o.f Name in Finnish
 * @param {string} data.y Employment type ("Määräaikainen", ...?)
 * @param {string} data.m Link to image.
 * @param {string} data.g Link to logo.
 * @param {string} data.l Language code.
 * @param {string} data.r Recruiter email username part.
 * @param {string} data.a Apply link suffix after 'https://staffpoint.talentadore.com/apply/'.
 */

class AdoreJob {
  #cities
  #data

  #occupation

  constructor (data) {
    this.#data = data
    this.#cities = data?.c.split(',') // Cities array.
    this.#occupation = { // Occupation (profession title)
      isco: data?.o?.i, // ISCO integer number
      nameFi: data?.o?.f // Name in Finnish
    }
  }

  get cities () {
    return this.#cities
  }

  get recruiter () {
    return this.#data?.r
  }

  get name () {
    return this.#data?.n
  }

  get company () {
    return this.#data?.c
  }

  get logo () {
    return this.#data?.g
  }

  get image () {
    return this.#data?.m
  }

  get language () {
    return this.#data?.l
  }

  get tags () {
    return this.#data?.t
  }

  get updated () {
    return this.#data?.u
  }

  get employmentType () {
    return this.#data?.y
  }

  get counties () {
    return this.#data?.e
  }

  get startDate () {
    return this.#data?.s
  }

  get description () {
    return this.#data?.d
  }

  get location () {
    return this.#data?.i
  }

  get companyDescription () {
    return this.#data?.b
  }

  get applyLinkSuffix () {
    return this.#data?.a
  }

  get dueDate () {
    return this.#data?.d
  }

  get occupation () {
    return this.#occupation
  }

  get link () {
    return `https://staffpoint.talentadore.com/apply/${this.#data?.a}`
  }

  // if null or undefined, return empty string
  getOrNull (value) {
    return value || ''
  }
}

export default AdoreJob
