export const ANALYTICS_STRING = 'Google Analytics'
export const GENESYS_STRING = 'Genesys Cloud'

const removeCookies = (service) => {
  const url = window.location.hostname
  if (service === GENESYS_STRING) {
    document.cookie = `_genesys.widgets.webchat.metaData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Domain=.${url};`
    document.cookie = `_genesys.widgets.webchat.state.open=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Domain=.${url};`
  } else if (service === ANALYTICS_STRING) {
    const gaCookies = document.cookie
      ?.split(';')
      ?.map(cookie => cookie.trim())
      ?.filter(cookie => cookie?.startsWith('_ga'))

    if (gaCookies) {
      for (const cookie of gaCookies) {
        document.cookie = `${cookie}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.${url}`
      }
    }
  }
}

export default function handleConsentListener () {
  const services = UC_UI.getServicesBaseInfo()
  const filteredServices = services.filter((s) => s.name === ANALYTICS_STRING || s.name === GENESYS_STRING)
  filteredServices.forEach(service => {
    if (service.consent.status) {
      window.sessionStorage.setItem(service.name, 'true')
      window.dispatchEvent(new Event(service.name))
    } else {
      window.sessionStorage.removeItem(service.name)
      window.dispatchEvent(new Event(service.name))
      removeCookies(service.name)
    }
  })
}
