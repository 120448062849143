/**
 * Not yet registered user which implements Firebase Authentication user record
 */
export default class NotRegisteredUser {
  /**
     * Create temporary user to accept terms before registering.
     * @param {string} email User's email address
     * @param {string} token Access token to provider's API
     */
  constructor (email, providerId, token) {
    this.displayName = email.split('@', 1)[0] // TODO Parse name better from email.
    this.email = email
    this.metadata = {}
    this.token = token
    this.phoneNumber = null
    this.photoURL = null
    this.providerId = providerId
    this.uid = null
  }
}
