import FirestoreTopCollection from '../google/firestore/FirestoreTopCollection'
import {
  addDoc,
  collection,
  getDocs,
  serverTimestamp,
  Timestamp,
  arrayUnion,
  doc,
  deleteDoc,
  orderBy,
  limit,
  query,
  startAfter
} from 'firebase/firestore'
import talentCardRep from './talentCardRep'
import expertiseRep from '../services/tags'
import { getUser } from '../google/auth/firebaseAuth'
import { db, toDocument } from '../google/firestore/firebaseFirestore'
import { difference } from 'lodash'

class TalentRepository extends FirestoreTopCollection {
  constructor () {
    super('talents')
  }

  async duplicate (id) {
    const talent = await this.getTalent(id)
    if (talent.duplicate != null) {
      const duplicate = talent.duplicate
      return duplicate
    }
  }

  async addSickLeave (name, phone, email, customer, start, end) {
    const myDocRef = await this._myDocRef()
    const sickLeaves = collection(myDocRef, 'sickLeaves')
    console.log(start, end)
    await addDoc(sickLeaves, {
      name,
      phone,
      email,
      customer,
      start: Timestamp.fromDate ? start : null,
      end: Timestamp.fromDate ? end : null
    })
  }

  // workHistory methods
  async workHistoryClear (idOfTalent, newWorkHistory) {
    const workHistoryRef = await getDocs(collection(db, `talents/${idOfTalent}/workHistory`))

    console.log(workHistoryRef)

    const prevWorkHistoryId = []
    workHistoryRef.forEach((item) => {
      prevWorkHistoryId.push(item.id)
    })

    difference(prevWorkHistoryId, newWorkHistory).forEach((item) => {
      deleteDoc(doc(db, `talents/${idOfTalent}/workHistory`, item))
    })
  }

  async workHistoryAdd (value, idOfTalent, timeID) {
    await this.setById(`${idOfTalent}/workHistory/${timeID}`, { value })
  }

  async getTalentWorkHistory (idOfTalent) {
    if (!idOfTalent) return []

    const ref = await getDocs(collection(db, `talents/${idOfTalent}/workHistory`))
    const docArray = []
    if (ref.empty) return docArray
    ref.forEach((item) => docArray.push({ ...item.data().value }))

    docArray.sort(function (a, b) {
      return new Date(a.timeStart) - new Date(b.timeStart)
    })

    return docArray
  }

  // WorkReferences methods

  async workReferencesClear (idOfTalent, newWorkHistory) {
    const workReferencesRef = await getDocs(collection(db, `talents/${idOfTalent}/workReferences`))
    const prevWorkReferencesId = []
    workReferencesRef.forEach((item) => {
      prevWorkReferencesId.push(item.id)
    })

    difference(prevWorkReferencesId, newWorkHistory).forEach((item) => {
      deleteDoc(doc(db, `talents/${idOfTalent}/workReferences`, item))
    })
  }

  async workReferencesAdd (value, idOfTalent, companyNameId) {
    await this.setById(`${idOfTalent}/workReferences/${companyNameId}`, { value })
  }

  async getTalentWorkReferences (idOfTalent) {
    if (!idOfTalent) return []
    const ref = await getDocs(collection(db, `talents/${idOfTalent}/workReferences`))
    const docArray = []
    if (ref.empty) return docArray
    ref.forEach((item) => docArray.push({ ...item.data().value }))
    return docArray
  }

  async castTalentDataForDB (talent, isCreating) {
    const talentForDb = { ...talent }
    let tagsObjects = []
    /* Save new tags/tags if any */
    if (talent.tags && talent.tags.length > 0) {
      tagsObjects = await Promise.all(
        talent.tags.map(async (tag) => {
          if (tag.id == null) {
            // means it's a new one
            // Get the id of newly added tag
            const result = await expertiseRep.add({
              name: tag.name
            })
            console.debug(result.id)
            return { id: result.id, name: tag.name }
          }
          return tag // return the basic one (already in db)
        })
      )
    }
    talentForDb.tags = tagsObjects
    const user = await getUser()
    if (isCreating) {
      talentForDb.created = {
        date: serverTimestamp(),
        id: user.id,
        name: user.name
      }
    }
    talentForDb.updated = {
      date: serverTimestamp(),
      id: user.id,
      name: user.name
    }
    return talentForDb
  }

  /* Firestore's setDoc function with merge isn't working here for some reason,
   it sets status to "presented" but deletes all other fields in the document */
  async changeTalentStatus (talents) {
    if (!talents) return
    talents.forEach(async (talentId) => {
      await this.setStatus(talentId, 'presented')
    })
  }

  async createTalent (id, talent) {
    const obj = await this.castTalentDataForDB(talent, true)
    await this.setById(id, obj)
    return talentCardRep.createTalentCard(id, obj)
  }

  async deleteTalent (talentId) {
    // Delete from cards first
    await talentCardRep.delete(talentId)
    return this.delete(talentId)
  }

  /* This uses copy & paste logic for tags objects, find a unified way */
  async editTalent (id, talent) {
    const obj = await this.castTalentDataForDB(talent)
    await this.mergeById(id, obj)
    return talentCardRep.editTalentCard(id, obj)
  }

  async getTalent (id) {
    const talent = await this.getById(id)
    return talent
  }

  /**
   * Get all talents ordered by added date in parts to make loading more consistent
   *
   * NOTES:
   *
   * This method is recursive and will fetch all documents in parts
   *
   * If the fetch fails, the rate will be decreased by 10 and the method will be called again until it reaches 10, when the error is thrown again.
   * @param {number} rate How many documents to fetch at a time.
   * @param {*} prevLast Internal variable. Used for recursively getting all documents
   * @param {*} prevRes Internal variable. Used for recursively getting all documents
   * @returns {array} Array of talents
   */
  async getTalentsInParts (rate = 100, prevLast = null, prevRes = null) {
    try {
      let querySnapshot = query(this.collection, orderBy('added', 'desc'), limit(rate))

      if (prevLast) {
        querySnapshot = query(this.collection, orderBy('added', 'desc'), limit(rate), startAfter(prevLast))
      }
      const result = await getDocs(querySnapshot)
      if (result.empty) return prevRes
      const docs = []
      result.forEach(documentSnapshot => docs.push(toDocument(documentSnapshot)))
      if (docs.length < rate) return [...prevRes, ...docs]

      return this.getTalentsInParts(rate, result.docs[result.docs.length - 1], prevRes ? [...prevRes, ...docs] : docs)
    } catch (e) {
      console.error('error fetching talents, trying to reduce rate of', rate, 'by 10...')
      if (rate > 10) return this.getTalentsInParts(rate - 10, prevLast, prevRes || [])
      else throw e
    }
  }

  async getTalents () {
    const result = await this.getAllOrderedByAddedFirst()
    return result
  }

  // Educations methods

  async educationsClear (idOfTalent, newEducations) {
    const educationsRef = await getDocs(collection(db, `talents/${idOfTalent}/educations`))
    const prevEducationsId = []
    educationsRef.forEach((item) => {
      prevEducationsId.push(item.id)
    })
    difference(prevEducationsId, newEducations).forEach((item) => {
      deleteDoc(doc(db, `talents/${idOfTalent}/educations`, item))
    })
  }

  async educationsAdd (value, idOfTalent, educationsId) {
    await this.setById(`${idOfTalent}/educations/${educationsId}`, { value })
  }

  async setTalentForm (id, talentForm) {
    await this.updateById(id, talentForm)
  }

  async setWeeklyHours (id, weeklyHours) {
    await this.updateById(id, weeklyHours)
  }

  async setWorkingHours (id, workingHours) {
    await this.updateById(id, workingHours)
  }

  async getTalentEducations (idOfTalent) {
    if (!idOfTalent) return []

    const ref = await getDocs(collection(db, `talents/${idOfTalent}/educations`))
    const docArray = []
    if (ref.empty) return docArray
    ref.forEach((item) => docArray.push({ ...item.data().value }))
    return docArray
  }

  async getTalentI18n (idOfTalent) {
    if (!idOfTalent) return []

    const ref = await getDocs(collection(db, `talents/${idOfTalent}/i18n`))
    const docArray = []
    if (ref.empty) return docArray
    ref.forEach((item) => docArray.push({ ...item.data(), id: doc.id }))
    delete docArray[0].id
    return docArray[0]
  }

  async setDuplicate (id, duplicate) {
    await this.updateById(id, { duplicate })
  }

  async setEmployedElsewhere (id, employedElsewhere) {
    await this.updateById(id, { employedElsewhere })
  }

  async setEvaledLang (id, evaledLang) {
    await this.updateById(id, {
      evaledLang
    })
  }

  async setPresentation (id, text) {
    await this.updateById(id, {
      presentation: text
    })
  }

  // used by WorkPermitForm
  // todo: simplify
  async setPermit (id, text) {
    try {
      this.updateById(id, {
        permit: text
      })
    } catch (error) {
      console.warn(error)
    }
  }

  async setPermitActive (id, text) {
    try {
      this.updateById(id, {
        permitActive: text
      })
    } catch (error) {
      console.warn(error)
    }
  }

  async setPermitApplied (id, text) {
    try {
      this.updateById(id, {
        permitApplied: text
      })
    } catch (error) {
      console.warn(error)
    }
  }

  async setJobSeeker (id, isJobSeeker) {
    this.updateById(id, {
      jobSeeker: isJobSeeker
    })
  }

  async setStatus (id, newStatus) {
    this.mergeById(id, {
      status: newStatus
    })
  }

  async setVideoInterviewDone (id, videoInterviewDone) {
    this.updateById(id, {
      videoInterviewDone
    })
  }

  /**
   * Talent status document <date, uid>
   * @typedef {Object} TalentStatusDocument
   * @property {string} presented Date in ISO when talent was presented to assignment
   * @property {string} presenter uid of consultant that set the talent to presented
   */

  /**
   * Gets talent's jobs collection status documents
   * @param {string} idOfTalent Id of talent
   * @returns {TalentStatusDocument[]} The jobs status array of documents
   */
  async getTalentStatuses (idOfTalent) {
    const ref = await getDocs(collection(db, `talents/${idOfTalent}/jobs`))
    const docArray = []
    if (ref.empty) return docArray
    ref.forEach((item) => docArray.push({ ...item.data(), id: item.id }))
    return docArray
  }

  /**
   * Gets the specific job status of specific talent
   * @param {string} idOfTalent id of talent
   * @param {string} idOfAssignment id of assignment
   * @returns {{TalentStatusDocument | object}} Status document of talent
   */
  async getTalentStatus (idOfTalent, idOfAssignment) {
    return await this.getById(`${idOfTalent}/jobs/${idOfAssignment}`)
  }

  /**
   * Sets talent's jobs collection status document
   * @param {string} idOfTalent Id of talent
   * @param {string} idOfAssignment Id of assignment in jobs subcollection
   * @param {TalentStatusDocument} _status The document to write <date, uid>
   */
  async setTalentStatus (idOfTalent, idOfAssignment, _status) {
    this.setById(`${idOfTalent}/jobs/${idOfAssignment}`, {
      status: arrayUnion(_status)
    })
  }

  /**
   * Reverts talent status to last presented state
   * @param {string} idOfTalent Id of talent
   * @param {string} idOfAssignment Id of assignment in jobs subcollection
   */
  async revertTalentStatus (idOfTalent, idOfAssignment) {
    const talentStatuses = await this.getTalentStatus(idOfTalent, idOfAssignment)
    if (!talentStatuses) return
    const index = talentStatuses.status.findLastIndex(status => status.presented)
    if (index === -1) return
    const revertedStatuses = talentStatuses.status.slice(0, index + 1)
    if (revertedStatuses && talentStatuses.status !== revertedStatuses) this.updateById(`${idOfTalent}/jobs/${idOfAssignment}`, { status: revertedStatuses })
  }

  // /**
  //  * Deletes talent status document in talents jobs subcollection
  //  * @param {string} idOfTalent id of talent
  //  * @param {string} idOfAssignment id of assignment document
  //  */
  // async deleteTalentStatus (idOfTalent, idOfAssignment) {
  //   this.delete(`${idOfTalent}/jobs/${idOfAssignment}`)
  // }
}

const talentRep = new TalentRepository()
export default talentRep
