import { useContext } from 'react'
import { localeContext } from '@saima/common/i18n'
import BackgroundPage from './BackgroundPage'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'
import Link from '@mui/material/Link'

export default function LoginHelpDialog () {
  const loc = useContext(localeContext)
  const t = loc.useT('StaffPointLogin')
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <BackgroundPage help sx={{ minHeight: '100vh', minWidth: '22rem' }}>
      <Box bgcolor={theme.palette.background.main} padding='2em' margin={smallScreen ? '1em' : '5em'} style={{ zIndex: 2 }}>
        <Stack direction='row' justifyContent='space-between' mb={5}>
          <Typography variant='h1'>
            {t('loginHelpTitle')}
          </Typography>
          <img
            height={40}
            width={40}
            src='/bubbles.png'
          />
        </Stack>
        <Stack mb={4}>
          <div><b>1.</b> {t('loginHelpDialogText1')}</div>
          <div><b>2.</b> {t('loginHelpDialogText2')}</div>
          <div><b>3.</b> {t('loginHelpDialogText3')}</div>
          <div><b>4.</b> {t('loginHelpDialogText4')}</div>
          <div><b>5.</b> {t('loginHelpDialogText5')}</div>
        </Stack>
        <Stack direction='row'>
          {t('loginHelpDialogText6')}
          &nbsp;
          <Link href='mailto:mystaff@staffpoint.fi'>mystaff@staffpoint.fi</Link>
          .
        </Stack>
      </Box>
    </BackgroundPage>
  )
}
