import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'

export default function Spinner (props) {
  return (
    <StyledSpinner {...props}>
      <CircularProgress size={props.size} color={props.color} />
    </StyledSpinner>
  )
}
const StyledSpinner = styled('div', {
  shouldForwardProp: (prop) => prop !== 'centered'
})(({ centered, theme }) => ({
  display: 'flex',
  padding: '16px 16px',
  ...(centered &&
    {
      width: '100%',
      justifyContent: 'center'
    })
}))
