import isEmpty from 'lodash/isEmpty'
import FirestoreTopCollection from '../google/firestore/FirestoreTopCollection'
import industryRepository from '../services/industryRepository'
// import { isSlateTextEmpty, serializeSlateText } from '../utils/text'

class TalentCardRepository extends FirestoreTopCollection {
  constructor () {
    super('talentCards')
    this._auditable = false
  }

  /* Get talent cards and reduce tag object array into string array for UI */
  async getTalentCards () {
    const result = await this.getAllOrderedByAddedFirst()
    const simplifiedResult = result.map(talent => {
      const simpleTalent = { ...talent }
      console.log(talent)
      if (talent.tags && talent.tags.length > 0) simpleTalent.tags = talent.tags.map(tag => tag.name)
      return simpleTalent
    })
    return simplifiedResult
  }

  /* Get talent with simplified tags */
  async getTalentCard (id) {
    const talent = await this.getById(id)
    const simpleTalent = { ...talent }
    console.log(talent)
    if (talent.tags && talent.tags.length > 0) simpleTalent.tags = talent.tags.map(tag => tag.name)

    return simpleTalent
  }

  async createTalentCard (refId, talent) {
    const talentCardObject = await this.getTalentCardObject(refId, talent)
    return await this.setById(refId, talentCardObject)
  }

  async editTalentCard (id, talent) {
    const talentCardObject = await this.getTalentCardObject(id, talent)
    return this.mergeById(id, talentCardObject)
  }

  async getTalentCardObject (userId, talent) {
    const { shortDescription, tags, industry, photo, about, location } = talent
    let industryCollection = null
    if (!isEmpty(industry)) {
      industryCollection = await industryRepository.getById(industry)
    }
    const talentCardObject = {
      talentName: `${talent.firstName} ${talent.lastName}`,
      shortDescription: shortDescription || null,
      // skills: isSlateTextEmpty(skills) ? null : serializeSlateText(skills),
      tags,
      photo: photo || null,
      industryName: !isEmpty(industry) ? industryCollection.name : null,
      location: location || null,
      about: about || null
    }
    return talentCardObject
  }
}

const talentCardRep = new TalentCardRepository()
export default talentCardRep
