import { DateTime } from 'luxon'
import usersRep from '../../user/UsersRepository'
import { refreshToken } from '../auth/firebaseAuth'

/**
 * User account in Firebase authentication.
 */
export default class UserAccount {
  /**
   * Create user account wrapper
   * @param {NotRegisteredUser|import('@firebase/auth').User} user Not registered or Firebase user record
   * @param {object} document User document snapshot
   */
  constructor (user, document = null) {
    if (!user?.employee) refreshToken() // only for firebase users
    this._document = document
    this._user = user
  }

  /**
   * Does this user have an administrator role.
   * @returns {boolean}
   */
  get admin () {
    // TODO Use token instead: this._user.token.admin
    // return admins.includes(this.name)
    // will always be false, if no claims found
    if (this.claims) {
      return this.claims.admin
    } else {
      return false
    }
  }

  get myStaff () {
    // return this.claims.my
    // will always be false, if no claims found
    if (this.claims) {
      return this.claims.my
    } else {
      return false
    }
  }

  /**
   * Does this user have an administrator role.
   * @returns {boolean}
   */
  get userManager () {
    if (this.claims) {
      return this.claims.userManager
    } else {
      return false
    }
  }

  get claims () {
    if (this._user.reloadUserInfo) {
      if (this._user.reloadUserInfo.customAttributes) {
        return JSON.parse(this._user.reloadUserInfo.customAttributes)
      }
    }
  }

  /**
   * Is this user accepted analytics terms.
   * @returns {boolean}
   */
  get analytics () {
    return this._document.analytics
  }

  /**
   * Time the user was created.
   * @returns {DateTime}
   */
  get created () {
    return this._metadata(true)
  }

  /**
   * Is this user authenticated via custom provider (LinkedIn).
   */
  get customProvider () {
    return this._user.providerId === 'firebase'
  }

  /**
   * The email of the user.
   * @returns {string}
   */
  get email () {
    return this._user.email
  }

  /**
   * myStaff employee role
   */
  get employee () {
    return this._user.employee
  }

  /**
   * The user's unique ID, scoped to the project.
   * @returns {string|null}
   */
  get id () {
    return this._user.uid
  }

  /**
   * Time the user last signed in.
   * @returns {DateTime|null}
   */
  get lastSignIn () {
    return this._metadata(false)
  }

  /**
   * Is this user accepted marketing terms.
   * @returns {boolean}
   */
  get marketing () {
    return this._document.marketing
  }

  /**
   * Gets myStaff account info
   */
  get userInfo () {
    return this._document._user
  }

  /**
   * The display name of the user.
   * @returns {string}
   */
  get name () {
    return this._user.displayName
  }

  /**
   * The phone number normalized based on the E.164 standard (e.g. +16505550101) for the user.
   * @returns {string|null} This is null if the user has no phone credential linked to the account.
   */
  get phone () {
    return this._user.phoneNumber
  }

  /**
   * The profile photo URL of the user.
   * @returns {string|null}
   */
  get photo () {
    return this._user.photoURL
  }

  /**
   * Is this user registered to Firebase Authentication and accepted the terms.
   * @returns {boolean}
   */
  get registered () {
    return !!this.id
  }

  /**
   * Does this user have a talent role.
   * @returns {boolean}
   */
  get talent () {
    return this.registered && !this.admin
  }

  /**
   * Testing getting menu items by user type
   */
  static getMenuItems () {
    console.log('user account: ' + this._user)
    if (this._user.employee) {
      return 'employee'
    }
    return 'not employee'
  }

  _metadata (created) {
    const millis = this._user.metadata[`${created ? 'created' : 'lastLogin'}At`]
    return millis ? DateTime.fromMillis(parseInt(millis)) : null
  }

  async load () {
    this._document = await usersRep.getById(this.id)
    return this
  }

  async update (analytics, marketing) {
    return await usersRep.update(this._document, analytics, marketing)
  }
}
